// topbar menu styles start
.topBarImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 10px

}

.topBarImageAlignment {
    text-align: center;
}
body a:hover {
    color: $color-template;
}
.theme-light .form input, .theme-light .form textarea, .theme-light .topbar__link-icon {
    color: black;
}
.themeColorText {
    color: $color-template;
}
.paginationcss {
    margin-right: 0;
}
.topbarMenu {
    width: 200px;
    border-radius: 0;
    border: none;
    padding-bottom: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
} 
.theme-white {
    // border: 1px solid #ddd !important;
    background: white !important
}
.iconstyle {
    background: #d6d7da !important;
    border: 1px solid #d6d7da !important;
}


// topbar menu styles end
.form input {
    background :white;
    margin-bottom: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-operator {
    display: none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
    display: none;
}
.p-column-filter-buttonbar .p-button:not(.p-button-icon-only) {
    width: 100%
}
.p-column-filter-menu-button, .p-column-filter-clear-button {
    color : white
}
body .p-datatable .p-datatable-tbody > tr.p-highlightHoliday {
    background-color: #fbff2b;
}
body .p-datatable .p-datatable-tbody > tr.p-highlightWeekend {
    background-color: #1efd00;
}
body .p-datatable .p-datatable-tbody > tr.p-highlightLessThan6Hrs {
    background-color: #ff0000;
}
body .p-datatable .p-sortable-column .p-sortable-column-icon { 
    color:$color-white;
}
.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
    font-size: 18px;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active {
    background: $button-text-color;
    color: $button-bg-color;
}
body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width:2em;
    height: 32px;
}
body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 12px;
}
body .p-button {
    background-color: $button-bg-color;
    border-color: $button-bg-color;
    color: $button-text-color;
    padding: 8px;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        background-color: $button-bg-color;
        border-color: $button-hover-color !important;
        color: $button-text-color !important;
    }
}
.p-button:enabled:hover,.p-button:enabled:active {
    background-color: $button-hover-color;
    border-color: $button-hover-color ;
    color: $button-text-color;
}

.p-button.p-button-outlined {
    color: $button-bg-color;
}

.p-button.button-filter {
    padding: 8px 14px;
    font-size: small;
    height: 42px;
}

.p-button-sm.button-filter{
    padding: 15px;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open {
    background: $button-bg-color;
    border-color: $button-bg-color;
    color: $button-text-color;
}

//autocomplete multi-select
.p-autocomplete-input-token {
    height: 25px;
}
.p-autocomplete-token.p-highlight{
    margin-bottom: 2px;
}
 // Dasboard 
.dashBoardCardWithScroll {
    height: 390px;
}

.p-column-title {
    text-transform: capitalize !important;
    font-size: 16px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

//Scrollable table with Sticky Header
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}
.table-fixed tr:after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
}
.table-fixed tbody td,
.table-fixed thead > tr > th {
    float: left;
}
//kpiTable
.kpiTable {
    background-color: $color-template;
    color: black;
}

.colorLegend {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

body .p-multiselect-panel .p-multiselect-items{
    color: black
}

// body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
//     background-color:$color-template;
    
// }
body .p-checkbox .p-checkbox-box.p-highlight{
    background-color: $color-template;
    border-color: $color-template
}
body .p-radiobutton .p-radiobutton-box.p-highlight {
    background-color: $color-template !important;
    border-color: $color-template !important;
}

body .p-input-overlay-visible{
    z-index: 9999 !important;
}

.color-picker__popover{
    z-index: 9999;
}

.calendar .rbc-off-range-bg {
    background-color: #e5e5e5;
}

.textElipses {
    font-weight: bold;
    color: $color-template;
    cursor: pointer;

    >a>img {
        width: 40px;
        height: 20px;
        margin: 5px;
    }
}
// .p-dropdown .p-dropdown-trigger {
//     height: 100%;
// }
// .css-vj8t7z{
//     min-height: 0 !important
// }
// .css-1hwfws3{
//     position: static !important;
// }
// .css-10nd86i react-select{
//     height: 33px
// }
// .css-2o5izw .react-select__control .react-select__control--is-focused{
//     height: 33px;
// }

.botmessagesText{
    padding:5px;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 8px;
    padding-left: 7px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: 5px;
    align-self: flex-start;
    width:90%;
    border-color: red
}
.usermessagesText {
    background-color: #f0f1ef;
    padding-top: 5px;
    padding-left: 7px;
    margin-right: 5px;
    margin-top: 7px; 
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 90%;
    text-align: left
}
.p-multiselect-label-container{
    height: 30px;
}

.p-autocomplete-input-token input{
    border:none
}

.logout_modal_width{
    max-width: 400px;
}

.call_action_column{
    text-align: 'center';
    text-decoration:underline;
    color:$color-template;
    font-weight: bold;
    cursor: pointer;
}

.p-datatable-row{
    cursor: initial !important;
}
.p-datatable-row:hover{
    background: transparent
}
.settings_form{
    width: 23%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: solid 1px lightgray;
}
.form .settings_form:focus, .form .settings_form:active{
    outline: none;
    border-color: #ffcd08;
}

.pending_badge,.started_badge{
    padding: 5px;
    font-size: 14px;
    margin-bottom: 2px;
    padding-left: 7px;
    padding-right: 7px;
    color: white;
}
.vertical_middle {
    justify-content: center;
    align-items: center;
    display: flex;
}
body .p-component {
    font-family: unset;
}
.pagenumber-indicator{
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 5px;
}
.rdw-editor-toolbar{
    img{
        height: 20px;
        width: 20px;
    }
}
.p-column-header-content:has(.p-checkbox){
    justify-content: center;
}
.p-filter-column{
    text-align: center !important;
    padding: 0.40rem !important;
    .p-inputtext{
        padding:0.45rem 0.75rem;
    }
    .p-column-filter-clear-button{
        display: none;
    }
    .p-multiselect-label {
        padding:0.25rem 0.75rem;
        color: black;
    }
    
}
.p-autocomplete input{
    width:100%;
}

.p-button{
    &:hover{
        background: $color-template !important;
        border-color: $color-template
    } 
    &-danger {
        &:hover {
            background-color: $color-red !important;
            border-color: $color-template; /* Assuming you want the border color from the template */
        }
    }
}

.p-buttonset .p-button {
    border-color: white;
}

.ck-content {
    min-height: 30vh;
}

// .p-button.p-button-icon-only{
//     height: 3.2rem;
// }

.p-autocomplete-multiple-container{
    width: 100% !important;
}

.input-spacing {
    margin-right: 10px;
}

.dropdown-spacing {
    margin-right: 10px;
}

//questions css
.questions-body {
    padding: 5px;
}

button-spacing {
    margin-top: 20px;
    transition: all 0.3s;
}

button-spacing:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.top-right-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    transition: all 0.3s;
    margin-top: 80px;
}

.top-right-button:hover {
    background-color: #ddd;
}

.total-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.horizontal-container {
    display: flex;
    align-items: center;
    gap: 10px;
    // padding-top: 10px;
    margin-top: 5px;
    width: 95%;
    margin-right: 5px;
}

.horizontal-container label {
    width: 20%;
    text-align: right;
    font-weight: 600;
    margin-right: 10px;
}

.horizontal-container .p-inputtextarea,
.horizontal-container .p-dropdown,
.horizontal-container .p-inputtext {
    width: 60%;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    transition: all 0.3s;
}

.p-inputtextarea:focus,
.p-dropdown:focus,
.p-inputtext:focus {
    border-color: #667eea;
    box-shadow: 0 0 0 3px rgba(102, 126, 234, 0.3);
}

.delete-icon .p-button-danger {
    margin-top: 30px;
    margin-left: 10px;
}

.field {
    padding-right: 10px;
    font-weight: bold;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
}

.savebtn {
    margin-left: 5px;
    background-color: #667eea;
    color: #ffffff;
    transition: all 0.3s;
}

.savebtn:hover {
    background-color: #5a67d8;
}

.questionsDropdown {
    width: 220px;
}

//forms css

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: 1px solid #e5e5e5;
    padding: 10px;
    // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    // background-color: #EAF4E8;
    // background-color: #FAFAF4;
    // border-radius: 10px;
}

.button-group {
    display: flex;
    gap: 16px;
    margin-bottom: 20px;
}

.view-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
}

.form-container {
    display: grid;
    place-items: center;
    margin-top: 20px;
    // width: 80%;
}

.default-fields {
    padding-bottom: 10px;
    width: 400px
}

.field-container {
    margin-bottom: 25px;
    width: 100%;
}

.question {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
    color: #333;
}

.option-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 15px;
    margin-top: 7px;
}

.field-container .option-container label {
    margin-left: 10px;
}

.field-container .p-dropdown {
    width: 250px;
    margin-top: 10px;
}

.field-container .p-inputtextarea {
    width: 350px;
    margin-top: 10px;

}

#dropdown-4 {
    margin-top: 10px;
}

.form-heading {
    margin-bottom: 20px;
    text-align: center;
    color: #007bff;
}

.questions-container {
    margin-top: 20px;
    width: 100%;
}

.editor-container {
    align-items: center;
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.templates-dropdown {
    margin-top: 10px;
}

.templates-container p {
    margin-left: 1% !important;

}

.ckeditor-container {
    width: 90%;
    margin: 0 auto;
}

.editor-style {
    font-family: 'Arial, sans-serif';
    font-size: '16px';
    line-height: '1.5';
    color: '#333';
    border: '1px solid #e0e0e0';
}

.templateStyle{
    border-bottom: 2px solid;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    background-color: white;
}

.slot-booking-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 16px;
    padding: 16px;
  }
  
  .time-block {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr); 
    gap: 8px;
    padding: 8px;
    border: 1px solid #ccc; 
  }
  
  .slot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #dff0d8; 
    cursor: pointer;
  }
  
  .slot.booked {
    background-color: #f2dede;
    cursor: not-allowed;
  }
   
  .my-calendar-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
//dialog css
  .custom-dialog .p-field {
    margin-bottom: 1rem; 
  }
  
  .custom-dialog label {
    display: block;
    margin-bottom: 0.5rem; 
    font-weight: bold; 
  }
  
  .custom-dialog .p-inputtext, 
  .custom-dialog .p-calendar {
    width: 100%; 
    padding: 0.5rem;
  }
  

  .custom-dialog-header {
    font-size: 1.5rem; 
    text-align: center;
    margin-bottom: 2rem; 
  }
  
  
  .custom-dialog {
    padding: 2rem; 
    border-radius: 0.5rem; 
    box-shadow: none !important;
  }

//calendar-form
.calendar-sidebar{
    background-color: #f4f4f4;
    padding: 1px;
    height: 85vh;
    // overflow-y: auto; 
  }
.calendar-form-container {
    background-color: #fff;
    position: relative;

  }
  
.schedule-dialog-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(60, 97, 119);
    height: 2.6rem;
  }
  
.schedule-dialog-header .calendar-form-header {
    color: white; 
    flex-grow: 1;
    text-align: center;
    font-size: 18px;
  }
  
  .close-icon {
    position: absolute;
    right: 28px;
    border: none;
    background-color:rgb(60, 97, 119) ;
    color: white;
    cursor: pointer; 
    transition: background-color 0.5s;
    font-size: 22px;
  }

  .view-close-icon{
    font-size:18px;
    border: none;
    background-color:rgb(60, 97, 119) ;
    color: white;
    transition: background-color 0.5s;
    display: flex;
    margin-right: 10px;
    margin-top: 2px;
    font-size: 22px;
  }
  .view-close-icon:hover {
    background-color: rgb(90, 127, 149); 
  }
  .close-icon:hover {
    background-color: rgb(90, 127, 149); 
  }

  .calendar-form {
    /* Add any additional styling to the form if needed */
    // padding-top: 25px;
    margin: 0px 10px;
  }
  
  .calendar-form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    padding-top: 10px;
  }
  
  /* Styling for the input fields, select boxes, etc. */
  .calendar-form-group input,
  .calendar-form-group select {
    width: 100%;
    padding: 8px;
    // margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  
  .calendar-form-group .p-button.p-button-icon-only {
    height: 2.8rem;
}

  .calendar-form-container .button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  
  .calendar-form .form-group{
    margin-bottom: 10px; 
  }

  .calendar-form .form-label {
    display: block;
    margin-bottom: 10px; 
    padding-left: 5px;
    font-weight: bold;
  }
  
//   .calendar-form .large-label{
//     font-size: 1.5em;
//   }
.calendar-form .time-container {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }
  
  .calendar-form .time-label {
    width: 80px; 
    text-align: right;
    margin-right: 8px; 
    font-size: 1.2em;
    padding-top: 10px;

  }
  
  .calendar-form  .time {
    font-size: 1.2em;
    padding-top: 10px;

  }
  
  .calendar-form .form-component{
    margin-bottom: 10px;
  }

  .calendar-form


  .calendar-form-container  .button:hover {
    background-color: white;
    color: black;
    border: 2px solid #4CAF50;
  }

  .patient-auto-complete .p-button.p-button-icon-only {
    height: 1.9rem;
}

.patient-nav-bar-main{
    width: 100%;
    position: fixed;
    top: 60px; 
    height: 60px;
    z-index: 100;
    background-color: #dedede;
    display: flex; 
    // justify-content: space-between;
    align-items: center; 
    padding: 0px 10px 0px 10px;

    .p-buttonset {
        button, .active {
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 0.5rem;
        }
    
        button p, .active p {
            color: white !important;
        }
    
        .active {
            background-color: white !important;
            color: #0e4768 !important;
        }
    
        .active p {
            color: #0e4768 !important;
        }
    }
    
    .insurance_company_name{
        color:white;
        background-color: #0e4768 ;
        margin: 0px 8px;
        padding: 2px 5px;
        border-radius: 5px;
    }
}
  .calendar-form-group-row {
    display: flex;
    justify-content: space-between;
  }
  
  .calendar-form-group-time {
    flex: 1;
    margin-right: 10px; 
  }
  
  
  .calendar-form-group-time:last-child {
    margin-right: 0;
  }
  
  .calendar-form-group-date {
    width: 100%;
    margin-bottom: 10px; 
    margin-top: 10px;
  }

  .patient-signature-button{
    padding: 10px;
     background-color: #3498db; 
     color: #fff;
      border: none;
       border-radius: 5px;
}

.patient-signature-button-dialog{
    width: fit-content;
    text-align: center;
    padding: 10px;
}
.form_form-header .pb-1{
    display: flex;
    align-items: center;
}

.calendar-sidebar .fromDateTimeContainer{
width: 215px !important;
}
.calendar-sidebar .daterangepicker{
    right: 0px !important;
    }
.calendar-sidebar .applyButton{
    background-color: #3c6177 !important;
}
.calendar-sidebar .form-control {
    width: 100% !important;
    height: 50px !important;
    margin-bottom: 5px !important;
    font-size: larger;
}

.calendar-sidebar .multiselectOperative .p-component {
   width: 100% !important;
}
.schedule-text .p-inputtext {
    width: 100%;
}

.drugsfield_add_icon {
    margin-left: 10px;
    padding: 4px;
    font-size: 12px;
}
.doautocomplete-label{
    // margin-bottom: 5px;
}

.calendar-form .p-tabview .p-tabview-nav {
    display: flex;
    justify-content: space-between;
}

.calendar-form .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.calendar-form  .rc-time-picker-clear-icon {
    content: "x";
    font-size: 12px;
    font-style: normal;
    color: #aaa;
    display: inline-block;
    line-height: 1;
    height: 20px;
    width: 20px;
    transition: color 0.3s ease;
    margin-top: 10px;
}

.p-datatable .p-column-header-content {
    display: flex;
    align-items: center;
    // font-weight: bold;
    justify-content: center;
    font-size: 16px;
}

.calendar-form .p-tabview .p-tabview-nav .p-tabview-ink-bar{
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 3px;
    background-color: #6366F1;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
} 

.drugsfield_action_deleteicon{
    padding: 2px;
    background-color:red;
    border-radius: 50%;
    vertical-align: middle;
    font-size: 23px !important;
}

.drugsfield_action_saveicon{
    padding: 2px;
    background-color:$color-template;
    border-radius: 50%;
    vertical-align: middle;
    font-size: 23px !important;
}

.prescription_download {
    font-size: 15px;

    .main_header {
        margin-bottom: 100px;
        display: flex;
        border-bottom: 2px solid;
        .provider_header{
            width: 100%;
            margin-top: 50px;
            margin-left: 20px;
            text-transform: capitalize;
            display: flex;
            justify-content: space-between;
            .email_content{
                text-transform: none !important; 
                font-weight: normal;
            }
        }
    }
    
    .header {
        font-weight: bold;
    }

    .pharmacy{
        font-size: 15px;
        div{
            padding-top: 0px;
            margin-top: 0px;
        }
    }

    .drug_header{
        font-size: 18px;
        font-weight: bold;
    }

    .signature{
        text-align: center;
        // border-top: 1px solid;
        // margin-top: 20px;
    }
    .signature_print{
        margin-top: -30px;
        margin-bottom: 50px;
    }
    .signature_line{
        display: block;
        margin-top: 60px;
    }

    div {
        padding-top: 3px;
    }
}
.calendar_form-header .text-white{
    color: black !important;
}

.appointment-calendar .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus  {
    box-shadow: none;
  }

.appointment-calendar {
    .p-tabview-panels{
        padding: 0px;
    }

    .Tab-Panel-Icon {
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        display: flex;
        align-items: center;
        position: relative;
        text-decoration: none;
        overflow: hidden;
        font-size: 25px !important;
    }

    .schedule_redirection {
        display: flex;
        text-align: right;
        color: red !important;
        padding: 10px;
        // width: 100%;
    }
}

.appointment-calendar .p-tabview .p-tabview-nav .p-tabview-ink-bar{
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 3px;
    background-color: #6366F1;
    border: 1px solid #6366F1;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
} 

.schedule-calendar .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus  {
    box-shadow: none;
  }

.schedule-calendar {
    padding: 10px 15px 15px 25px;
    .p-tabview-panels{
        padding: 10px 0px;
    }
    .Tab-Panel-Icon {
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        display: flex;
        align-items: center;
        position: relative;
        text-decoration: none;
        overflow: hidden;
        font-size: 25px !important;
    }
}

.schedule-calendar .p-tabview .p-tabview-nav .p-tabview-ink-bar{
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 3px;
    background-color: #6366F1;
    border: 1px solid #6366F1;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
} 
.entered_data{
    display: inline-block;
    font-weight: 700;
}
//Insurance

.insuranceCard {
    width: 99%;
    border-radius: 8px;
    padding: 20px;
    background-color: white;
    border: 1px solid #f7f5f5;
    margin: 10px;
    box-shadow:
     0px 3px 10px rgba(0, 0, 0, 0.2)
}

//Dilouge Header  &  Back icon

.p-dialog .p-dialog-header {
    height: 3.5rem !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 0.8rem !important;
    height: 0.8rem !important;
    color: white !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background: none !important;
}

.p-link:focus {
    box-shadow: none !important;
}

.p-button:focus {
    box-shadow: none !important;
}

.p-dialog-header {
    background-color: #3475aa !important;
    color: white !important;
    height: 70px !important;
    text-align: left;
    padding-top: 0.5rem;
}

.form {
    .padding_right_0{
        padding-right: 0px !important;
    }
}
// benifit information in insurance modal 
.benifts_data_table{
    width: 100%;
    height: 40px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 0 7px;
    margin-top: 0.5rem;
    
    &:hover, 
    &:focus {
    border-color: #6366F1;
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem #C7D2FE;
    }
}
.benifitDeleteActionIcon{
    padding: 3px;
    background-color:red;
    border-radius: 50%;
    vertical-align: middle;
    font-size: 30px;
}
// .svg-inline--fa.fa-w-11 {
//     width: 36% !important; 
// }
.benifitSaveActionIcon{
    padding: 4px;
    background-color:$color-template;
    border-radius: 50%;
    vertical-align: middle;
    font-size: 31px;
    width:100%;   
}
.p-datatable .p-datatable-thead > tr > th {
     padding: 5px !important; 
    
}

//table Funnel Filter
.p-column-filter-menu {
    // display: inline-flex;
     margin-left: 2px !important; 
     .p-column-filter-menu-button {
        color:rgb(184, 184, 187) !important;
    }
}


.treatment-plan-container .data-tables-container .hide-row {
    display: none;
  }
  
.display_form_data {
    @media screen and (max-width: 700px) {
        font-size: 10px;
    }
    @media screen and (min-width: 769px) and (max-width: 820px) {
        padding: 20px;
    }
    @media screen and (min-width: 821px) and (max-width: 1366px) {
        font-size: 18px;
        padding: 25px;
    }
    @media screen and (min-width: 1367px) and (max-width: 1439px) {
        font-size: 20px;
        padding: 25px;
    }
    @media screen and (min-width: 1440px) {
        font-size: 25px;
        padding: 30px;
    }
}

.treatment-plan-container .data-tables-container .hide-row {
    display: none;
  }
  
.treatmentplan-checkbox{
    width: 18px; 
    height: 18px; 
    cursor: pointer;
    margin-top: 2px;
}

//Treatment plan modal css
.custom-treatment-plan-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .custom-treatment-plan-form .full-width {
    grid-column: span 2;
  }
  
  .custom-treatment-plan-form label {
    display: block;
    margin-bottom: 0.5em;
  }
  
  .custom-treatment-plan-form input,
  .custom-treatment-plan-form textarea {
    width: 100%;
    padding: 0.5em;
    box-sizing: border-box;
  }
  .custom-treatment-plan-form .p-dialog .p-dialog-header  {
    background-color: #010c18;
    color: #ffffff;
  }  
  .custom-treatment-plan-form .p-field {
    padding-top: 10px;
}

//procedures status
.row-default > td {
    color: #4A0000 !important; /* Very Dark Red */
}

.row-referral > td {
    color: red !important;
}

.text-complete > td {
    color: #000080 !important; /* Navy Blue */
}

.custom-treatment-plan-form .p-button.p-button-icon-only {
    width: 2em;
    height: 42px;
}

.procedures-edit .p-button.p-button-icon-only {
    height: 2.8rem !important;
}

.procedures-edit .doautocomplete-label {
    margin-bottom: 0px !important;
}

.clock-in-out-picker .rc-time-picker {
    width: 100%;
    height: 35px;
}
.clock-in-out-picker .rc-time-picker-input {
    height: 50px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ced4da;
    padding: 6px 12px;
}

.patient-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.patient-notes-dialog{
    max-width: 60%;
    min-width: 60%; 
    width: auto; 
    max-height: 90vh; 
    overflow-y: auto;
}

.ckeditor-notes-container {
    height: 100%; 
    overflow-y: auto;
    width:100%;
    margin-top: 10px;    
}

.notes-history {
    overflow-y: auto;
    margin-top: 10px; 
    min-height: 350px; 
    min-width:350px;
}
.custom-header-left .p-column-header-content {
    justify-content: flex-start !important; /* Aligns content to the left */
}
.clock-in-out-picker .rc-time-picker-clear-icon:after {
    content: "x";
    font-size: 16px;
    font-style: normal;
    color: #aaa;
    display: inline-block;
    line-height: 1;
    height: 20px;
    width: 20px;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
    text-align: center;
}

.payment_loading_modal{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

 // Auto Notes List Box and Table Header Changes 
.scrollable-listbox{
    height: 500px;
    overflow-y: auto;
}
.no-header .p-datatable-thead{
    display: none;
}
.custom-no-header .p-datatable-emptymessage {
    display: none;
}
.p-fileupload-large .p-button.p-fileupload-choose{
    width: 100%;
}

.calendar-order {
    order: 1;
}

.procedure-pending {
    font-weight: 500 !important;
    background-color: #fae8ff !important;
}
.procedure-completed {
    font-weight: 500 !important;
    background-color: #cffafe !important;

}
.preferredAmount_color{
    font-weight: bold !important;
    background-color: #B1F5BF !important;
}
.discount-color {
    background-color: #fff1f2 !important;

}
.adjustment-color {
    background-color: #faf5ff !important;
}
.deposit-color {
    background-color: #f0fdfa !important;
}

.absolute__position {
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    padding: 15px 30px 30px 15px;
    background-color: #f3e8ff;
    left: -30px;
    top: -10px;
    overflow: hidden;

}
.icon__size {
    font-size: 12px !important;
}
.procedure_table_column_textOverflow{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.treatmentplan_button .btn{
    padding: 5px 12px;
}

// Data Table Custom Row class Name 
// .p-datatable .p-datatable-tbody{
    .feeSchedule_TableRow_Color{
        // background-color :#b7d6fc;
        background-color: #a2cbfa !important;
    }
// }

.txplan_priority {
    .p-autocomplete input,.p-autocomplete button,.p-dropdown {
        height: 34px;
    }
    .p-dropdown .p-placeholder {
        padding: 5px;
    }
}

.treatmentplan_edit_form {
    input.p-disabled, .p-dropdown.p-disabled {
        background-color: #f2f4f7;
    }
}

.p-resizable-column {
    padding: 5px !important;
    font-weight: 600 !important;
    font-size: 15px !important;
}

//file upload css
.p-fileupload-file-badge {
    display: none !important;
}

.p-fileupload .p-fileupload-content {
    padding: 0px !important;
}

.p-message-icon {
    display: none;
}

.p-message-wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
    max-width: 100%;
    overflow: auto;
}

.p-message-summary,
.p-message-detail {
    grid-column: 2;
}

.p-message-close {
    grid-column: 1;
    top: -45px;
}

//css for buttons in the login component 
.scale {
    transform: scale(0.80);

    button {
        white-space: nowrap;
    }
}

.text-info {
    color: rgb(47, 39, 131) !important;
}

.login-container .login4 .card .card-body img {
    width: 250px;
    height: 250px;
}

.p-dialog-header {
    background-color: $table-header-bg-color !important;
    color: $table-header-text-color !important;
    height: 4rem !important;
    text-align: left;
    padding: 0.5rem;
}

.p-dialog .p-dialog-header {
    height: 3.5rem !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 0.8rem !important;
    height: 0.8rem !important;
    color: white !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background: none !important;
}

.p-link:focus {
    box-shadow: none !important;
}

.p-button:focus {
    box-shadow: none !important;
}

.p-dialog .p-dialog-content {
    padding: 0 1rem .5rem 1rem;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
    height: 100%;
}

.p-calendar-w-btn-right .p-datepicker-trigger {
    height: 100%;
}


.ResizePanel-module_ResizeContentHorizontal__1gGbA {
    max-width: 100%;
}

.p-button.p-fileupload-choose {
    padding: 0.75rem;
}

.bulkuploadbutton .p-button.p-fileupload-choose{
    padding: 0.75rem;
    width: 26.3%;
}

//css for auto complete field dropdown panel if options having long text
.p-autocomplete-panel .p-autocomplete-item {
    white-space: normal;
    word-wrap: break-word;
    padding: 0.50rem 1rem !important;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    font-family: $font-type;
}

.textElipses>a>img {
    width: 40px;
    height: 20px;
    margin: 5px;
}

.p-dialog .p-dialog-header .p-dialog-title {
    text-transform: capitalize;
    font-size: 1.15rem;
}

.inline-multi-select .p-multiselect .p-multiselect-label {
    color: rgb(64, 56, 56);
}

.grid_header {
    color: $color-template;
    font-weight: 600;
}

.ant-picker-dropdown{
    z-index: 9999 !important;
}

.txplan_category .p-dropdown {
    width: 90%;
    text-align: left;
    .p-dropdown-label {
        height: 34px;
        padding: 6px;
        margin-right: 5px;
      }
}

.footer__bg_color{
    background-color: #f1f5f9 !important;
}

.procedure-codes-dropdown {
    width: 70%;
    .p-dropdown .p-dropdown-label {
        height: 38px;
        padding: 6px;
    }
}

.p-column-filter-menu {
    .pi-filter-icon {
        visibility: hidden;
        opacity: 0;
    }
}
.p-sortable-column:hover{
    .pi-filter-icon {
        color: #343a40;
    }
}
.p-sortable-column:hover, .p-resizable-column:hover ,.p-column-filter-menu-button-open ,.p-column-filter-menu-button-active{
    .pi-filter-icon {
        visibility: visible;
        opacity: 1;
    }

}

.p-sortable-column .p-sortable-column-icon {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s ease-in-out;
}

.p-sortable-column:hover .p-sortable-column-icon {
    visibility: visible;
    opacity: 1;
}

.p-sortable-column.p-highlight .p-sortable-column-icon {
    visibility: visible;
    opacity: 1;
    transition: none;
}

.activities-header .p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: #EEF2FF;
    color:rgb(60, 97, 119) !important;
}


.treatmentplan-autocomplete-field-container  .p-autocomplete-panel .p-component .p-ripple-disabled .p-connected-overlay-enter-done{
    max-height: 200px;
    width: 100px;
    z-index: 999 !important;
    min-width: 498.656px;
    transform-origin: center top;
    top: 279px;
    left: 240px;
}
.time-picker .rc-time-picker > .rc-time-picker-input {
    position: relative;
    font-family: var(--font-family);
    font-size: 14px;
    padding: 1.5rem !important;
    border: 1px solid var(--surface-border, #d9d9d9);
    border-radius: 4px;
    transition: box-shadow 0.15s ease-in-out, border-color 0.15s ease-in-out;
    color: var(--text-color, #495057);
    background-color: var(--input-bg-color, #fff);

    &:focus {
        border-color: var(--primary-color, #007ad9);
        outline: none;
    }
    margin: 0px !important;

}
.timepicker__clear__icon {
    cursor:pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}


.settings-content .p-card-content {
    padding: 5px !important;
}


//Patient screen changes
.patient_screens {
    height: calc(100vh - 150px);
    /* Full viewport height minus 120px */
}

.screen_cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 95%; 
}

.fullscreen {
    max-height: 100%;
    min-height: fit-content !important;
    width: 100%;
    background-color: $color-white;
    transition: all 0.8s ease;
    .container {
        padding: 5px !important;
        min-height: fit-content !important;
    }
}

.screen_card {
    flex: 1 0 47%;
    min-height:auto;
    max-height: calc(100% - 300px);
    box-sizing: border-box;
    margin: 10px;
    overflow-y: auto;
    background-color: white;
    border-radius: 2px;
    box-shadow: 2px 4px 8px rgba($color-template, 0.3);
    
}

.fullscreen .container, .screen_card .container{
    padding: 2px;
    height: 100% ;
    .grid_card{
        border: 1px solid  $color-template;
        box-shadow: 2px 4px 8px rgba($color-template, 0.3);
    }
    .cardForGridMargin .tableCardBody{
       padding: 5px 10px 0px 0px;
    }
}

.insurance,
.forms {
    max-height: 300px;
}

.insurance,
.forms {
    .card {
        padding: 0px;
    }

    .cardForGridMargin {
        grid-area: header;
        position: sticky;
        top: 0;
        z-index: 10;

        .tableCardBody>div {
            padding-bottom: 3px !important;
        }
    }
}


// / For screens larger than 1700px, hide the small header /
@media screen and (min-width: 1600px) {
    .screen-header-small {
        display: none;
    }
}

// / For screens smaller than 1700px, hide the large header /
@media screen and (max-width: 1599px) {
    .screen-header-large {
        display: none;
    }
}

//css for action buttons and pagination for screen headers
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    min-width: 2rem;
    width: 2.3rem;
    height: 2.3rem;
    margin: 0.12rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 2.3rem;
    height: 2.4rem;
    margin: 0.12rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-paginator .p-dropdown {
    height: 2.5rem;
}

.p-button.p-button-sm {
    padding: 0.5rem 1rem;
}

.inventory_table .tableCardBody {
    padding: 0px;
}

.scroll_container_body {
    // max-height: calc(100vh - 120px);
    overflow: auto;
}

.productName {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-word;
    line-clamp: 2;
    max-height: 3.5em;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    margin: 0.5rem 0;
    color: #374151;
    text-transform: capitalize;
  }

  .keyValueContainer {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px 16px;
    align-items: center;
    margin: 16px 0;
  }
  
  .key {
    font-size: .9rem;
    font-weight: bold;
    text-align: left;
    color: #374151;
    white-space: nowrap;
  }
  
  .value {
    font-size: .9rem;
    text-align: left;
    color: #555;
    word-wrap: break-word;
  }
  .add_to_cart_icon{
    position: absolute;
    top: 10px;
    right: 0;
    height: 30px;
    width: 30px;
  }

  .add__to__cart {
    transition: border-color 0.3s ease;
    border: 1px solid #e4e4e7;
    &:hover {
        border-color: #6366f1;
    }
  }

  .delete_cart__confirmation {
    background-color: #b91c1c !important;
    border: none;
    &:hover {
        background-color: #dc2626 !important;
    }
  }

  .expander__datatable .p-row-odd {
    background-color: #dbeafe !important;
}
  
.customPanelZIndex{
    z-index: 10000 !important;
}
.treatmentplan-dropdown-container{
    padding-left: 10px !important;
}

.treatmentplan-dropdown-container .treatmentplan-appointment-dropdown{
    height: 35px !important;
    width: 260px !important;
}

.treatmentplan-dropdown-container .treatmentplan-appointment-dropdown .p-dropdown.p-dropdown-clearable .p-dropdown-label {
padding: 5px !important;
}


.treatmentplan-dropdown-container .treatmentplan-appointment-dropdown .p-dropdown-label.p-placeholder {
    color: #6c757d !important;
    padding: 5px !important;
}

.treatmentplan-dropdown-container .treatmentplan-appointment-dropdown .p-inputtext{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
    padding: 5px;
}
/* Disable scrolling */
.body-no-scroll {
    overflow: hidden;
}




/* Styles for iPad Pro */
@media (min-width: 993px) and (max-width: 1050px) {
    .col-ipad {
        width: 12.5% !important;
    }

     #zipCode label {
        max-width: 50px !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        max-height: 20px !important;
    }
}
.row > .col-lg-2.col-md-2.col-ipad {
    padding-right: 0 !important;
}

.laboratories_input_field{
    width: 100%;
    height: 40px;
    border: 1px solid #dedede;
    border-radius: 5px;
    // padding: 0 7px;
    // margin-top: 0.5rem;
    margin-bottom: 0px !important;
}

.patient-img-upload .p-button-label.p-clickable {
    max-width: 85px !important;
    text-overflow: ellipsis !important;
    max-height: 30px !important;
    overflow: hidden !important;
}

.text-capitalize-first-letter::first-letter,
.text-capitalize-first-letter .p-multiselect-item-label::first-letter {
    text-transform: uppercase;
}